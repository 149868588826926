'use client';

import { ModuleScheduleSection } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { Navigation } from '@web/organisms/Header/molecules/Navigation';
import { SubNavigation } from '@web/organisms/Header/molecules/SubNavigation';
import { HeaderTemplate, useHeaderTemplate } from '@web/organisms/Header/utils/useHeaderTemplate';

import { useHeaderData } from './utils/useHeaderData';

import styles from './Header.module.scss';

export const Header = () => {
    const contextData = useContextData();
    const headerData = useHeaderData(contextData);
    const template = useHeaderTemplate();

    if (template === HeaderTemplate.HIDDEN) {
        return null;
    }

    const { menuState, toggleMenu } = headerData;
    const { items, subItems, submitSearch, currentNavItem } = headerData;

    const { modulesBySection } = contextData;
    const isNationalTheme =
        modulesBySection && ModuleScheduleSection.NATIONAL_THEME in modulesBySection
            ? modulesBySection![ModuleScheduleSection.NATIONAL_THEME]?.[0]
            : false;

    const classes = [styles.Header];

    if (menuState.menuOpen) {
        classes.push(styles['full-screen']);
    }

    const isMinimalHeader = template === HeaderTemplate.MINIMAL;

    return (
        <header id="head" className={classes.join(' ')}>
            {/* TOP  MENU */}
            <section className={`${styles.top} ${isNationalTheme ? styles['national-theme'] : ''}`}>
                <div className={`full-width ${styles['top-container']}`}>
                    <Navigation
                        items={items}
                        currentNavItem={currentNavItem}
                        toggleMenu={toggleMenu}
                        currentSearch={menuState.currentSearch}
                        onSubmitSearch={submitSearch}
                        notificationsCount={menuState.notificationsCount}
                        menuView={menuState.menuView}
                        menuOpen={menuState.menuOpen}
                        template={template}
                    />
                </div>
            </section>
            {/* SUB MENU */}
            {!isMinimalHeader && !menuState.menuOpen ? (
                <section className={styles.bottom}>
                    <div className="full-width">
                        <SubNavigation
                            items={subItems}
                            currentSearch={menuState.currentSearch}
                            onSubmitSearch={submitSearch}
                            currentNavItem={currentNavItem}
                            menuView={menuState.menuView}
                        />
                    </div>
                </section>
            ) : null}
        </header>
    );
};
