import { FC, useCallback, useState } from 'react';

import { Link } from '@common/atoms/Link';
import { NavItem } from '@common/clients/api';
import { useContextData } from '@common/useContextData';
import { useUserContext } from '@common/useUserContext/UserContextProvider';
import { InternalLink, Route } from '@web/atoms/InternalLink';
import { PlatformLogo, PositionVariant } from '@web/atoms/PlatformLogo/PlatformLogo';
import { ProTag } from '@web/atoms/ProTag';
import { ComponentList as NavItemList } from '@web/molecules';
import { ExpandableSearchBar } from '@web/molecules/ExpandableSearchBar';
import { HamburgerIcon } from '@web/organisms/Header/atoms/HamburgerIcon/HamburgerIcon';
import { LanguageSelector } from '@web/organisms/Header/molecules/LanguageSelector/LanguageSelector';
import { HeaderTemplate } from '@web/organisms/Header/utils/useHeaderTemplate';
import { getAdminUrl } from '@web/utils/getAdminUrl';

import { useHeaderThemeConfig } from '../../Header.config';
import { MenuView } from '../../utils/useHeaderData';
import { UserArea } from '../UserArea/UserArea';

import styles from './Navigation.module.scss';

export interface Props {
    items: NavItem[];
    currentNavItem?: NavItem;
    toggleMenu: () => void;
    currentSearch?: string;
    onSubmitSearch: (searchText: string) => void;
    notificationsCount?: number;
    menuView?: MenuView | '';
    menuOpen?: boolean;
    template: HeaderTemplate;
}

interface MenuState {
    expandedSearch: boolean;
}

export const Navigation: FC<Props> = ({
    items,
    currentNavItem,
    toggleMenu,
    currentSearch = '',
    notificationsCount,
    onSubmitSearch,
    menuView,
    menuOpen,
    template,
}) => {
    const { platform, context } = useContextData();
    const {
        state: { isLoggedIn },
    } = useUserContext();
    const themeConfig = useHeaderThemeConfig();
    const [state, setState] = useState<MenuState>({
        expandedSearch: !!currentSearch,
    });

    const expandSearch = useCallback((isExpanded: boolean) => {
        setState((prevState) => ({ ...prevState, expandedSearch: isExpanded }));
    }, []);

    const { expandedSearch } = state;

    const adminUrl = getAdminUrl(context.url);

    const classes = [styles.Navigation];
    if (menuOpen) classes.push(styles.fullscreen);
    if (themeConfig.isInverted) {
        classes.push('inverted');
    } else {
        classes.push(styles['light-background']);
    }

    const isMinimalHeader = template === HeaderTemplate.MINIMAL;

    return (
        <aside className={classes.join(' ')}>
            {!isMinimalHeader && !themeConfig.showMainItemsInSubNavigation ? (
                <span className={styles['hamburger-area']}>
                    <HamburgerIcon isInverted={themeConfig.isInverted} onClick={toggleMenu} />
                </span>
            ) : null}
            <span className={styles['logo-area']}>
                <InternalLink route={Route.Homepage}>
                    <PlatformLogo positionVariant={PositionVariant.HEADER} platformID={platform.id} />
                </InternalLink>
            </span>
            {!isMinimalHeader ? (
                <UserArea
                    adminUrl={adminUrl}
                    isLoggedIn={isLoggedIn}
                    notificationsCount={notificationsCount}
                />
            ) : null}
            {!isMinimalHeader ? (
                <nav
                    id="main-nav"
                    className={expandedSearch ? styles.collapsed : ''}
                    role="navigation"
                    aria-label="main-navigation"
                >
                    <NavItemList
                        ComponentItem={(item: NavItem) => (
                            <li className={item.id === currentNavItem?.id ? styles['is-active'] : ''}>
                                {item.name === 'pro' ? (
                                    <InternalLink route={Route.Premium}>
                                        <ProTag />
                                    </InternalLink>
                                ) : (
                                    <Link className={styles[item.class ?? item.name]} href={item.url}>
                                        {item.name}
                                    </Link>
                                )}
                            </li>
                        )}
                        propsList={items}
                    />
                </nav>
            ) : null}

            {!isMinimalHeader &&
            menuView === MenuView.DESKTOP &&
            themeConfig.showSearchBarInMainNavigation ? (
                <ExpandableSearchBar
                    additionalClass={`${styles['search-area']} ${expandedSearch ? styles.expanded : ''}`}
                    currentSearch={currentSearch}
                    onExpand={expandSearch}
                    onSubmit={onSubmitSearch}
                />
            ) : null}
            {!isMinimalHeader && menuView === MenuView.MOBILE ? (
                <ExpandableSearchBar
                    additionalClass={styles['search-area']}
                    currentSearch={currentSearch}
                    onSubmit={onSubmitSearch}
                />
            ) : null}
            {!isMinimalHeader && themeConfig.hasMultipleLanguages ? (
                <span className={styles['settings-area']}>
                    <LanguageSelector isInverted />
                </span>
            ) : null}
        </aside>
    );
};
