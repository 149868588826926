import { useEffect } from 'react';
import isString from 'lodash/isString';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';

import { getConfig } from '@cms/i18n';
import { FontFaces } from '@common/atoms/FontFaces/pages-router';
import { ThemeProvider } from '@common/atoms/ThemeProvider';
import { PlatformID } from '@common/clients/api';
import {
    ContextHash,
    decodeContextHash,
    getTheme,
    isContextData,
    isContextHash,
    ResolvedContextData,
} from '@common/defaults';
import { populateContextHashFromContextData } from '@common/defaults/populateContextHashFromContextData';
import { logger } from '@common/logger';
import { ThemeID } from '@common/types/ThemeID';
import { getPlatformIDByHostname } from '@common/utils/HostUtil';
import { sendGAEvent } from '@web/molecules/TrackingHandler';
import { Body } from '@web/templates/RootLayout/atoms/Body';
import '@common/logger/browser';

import { defaultNamespaces } from '../namespaces';
import CustomError from './_error';

import '../styles/index.scss';

const isDevelopment = process.env.APP_ENV === 'development';

function MyApp({ Component, pageProps: props, router }: AppProps) {
    const isServerSide = typeof window === 'undefined' || window.navigator.appVersion === 'undefined';

    const contextData: ResolvedContextData | undefined = isContextData(props?.contextData)
        ? props.contextData
        : undefined;
    const { userContext } = props;
    const contextHash: ContextHash | undefined = isContextHash(props?.contextHash)
        ? props.contextHash
        : router.query?.contextHash && isString(router.query?.contextHash)
          ? decodeContextHash(router.query.contextHash)
          : contextData
            ? populateContextHashFromContextData(contextData)
            : undefined;

    let origin = contextData ? contextData.hostname : '';

    logger.registerAdditionalContext({
        locale: router.locale,
        pathname: router.asPath,
        route: router.route,
    });

    const shouldShowErrorPage = (Boolean(props.errorCode) && !isDevelopment) || props.errorCode === 404;

    if (!isServerSide && (!origin || origin.length)) {
        origin = window?.location?.hostname ?? '';
    }

    const nextRouter = useRouter();
    useEffect(() => {
        // FIXME: Disable prefetch as long as we have PHP in the middle
        nextRouter.prefetch = async () => {};
        // FIXME: avoid router to handle non-shallow routes, e.g. /login
        const handleRouteChange = (url: string, options: any) => {
            if (options?.shallow === false) {
                window.location.pathname = url;
            }
        };
        nextRouter.events.on('routeChangeStart', handleRouteChange);
        return () => {
            router.events.off('routeChangeStart', handleRouteChange);
        };
    }, [nextRouter, router.events]);

    if (contextData && contextHash) {
        return (
            <>
                {shouldShowErrorPage ? (
                    <NextHead>
                        <meta name="robots" content="noindex, nofollow" />
                    </NextHead>
                ) : null}
                <Body
                    contextHash={contextHash}
                    contextData={contextData}
                    userContext={userContext}
                    isAppRouter={false}
                >
                    {shouldShowErrorPage ? <CustomError {...props} /> : <Component {...props} />}
                </Body>
            </>
        );
    } else {
        const platformID = getPlatformIDByHostname(origin);
        const themeID = router.route === '/liveness' ? ThemeID.VP : getTheme(origin);

        return (
            <ThemeProvider themeID={themeID}>
                <FontFaces platform={platformID || PlatformID.VP} />
                <NextHead>
                    <meta name="robots" content="noindex, nofollow" />
                </NextHead>
                {shouldShowErrorPage ? <CustomError {...props} /> : <Component {...props} />}
            </ThemeProvider>
        );
    }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
    logger.debug(metric);
    sendGAEvent('event', metric.name, {
        event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value),
        event_label: metric.id,
        non_interaction: true,
    });
}

export default appWithTranslation(MyApp, getConfig(defaultNamespaces));
