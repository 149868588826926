'use client';

import { FC, PropsWithChildren } from 'react';

import { SupertokensWrapper } from '@common/atoms/SupertokensWrapper';
import { ThemeProvider } from '@common/atoms/ThemeProvider';
import { ResolvedContextData } from '@common/defaults';
import { ResolvedUserContext } from '@common/defaults/UserContext';
import { ContextDataProvider } from '@common/useContextData';
import { UserContextProvider } from '@common/useUserContext/UserContextProvider';

interface Props extends PropsWithChildren {
    contextData: ResolvedContextData;
    userContext: ResolvedUserContext;
}

export const ContextProviders: FC<Props> = ({ children, contextData, userContext }: Props) => {
    return (
        <ThemeProvider themeID={contextData.themeID}>
            <SupertokensWrapper contextData={contextData} userContext={userContext}>
                <ContextDataProvider contextData={contextData}>
                    <UserContextProvider userContext={userContext}>{children}</UserContextProvider>
                </ContextDataProvider>
            </SupertokensWrapper>
        </ThemeProvider>
    );
};
