import { type ThemeConfig, useThemeConfig } from '@web/hooks/useThemeConfig';

export interface ThemeConfigProps {
    hasMultipleLanguages: boolean;
    hasSubscriptions: boolean;
    isInverted: boolean;
    showMainItemsInSubNavigation: boolean;
    showSearchBarInMainNavigation: boolean;
}

export const THEME_CONFIG: ThemeConfig<ThemeConfigProps> = {
    defaults: {
        hasMultipleLanguages: false,
        hasSubscriptions: false,
        isInverted: true,
        showSearchBarInMainNavigation: true,
        showMainItemsInSubNavigation: false,
    },
    byPlatform: {
        br: {
            isInverted: false,
            showSearchBarInMainNavigation: false,
        },
        gp: {
            hasMultipleLanguages: true,
            showSearchBarInMainNavigation: false,
        },
        vi: {
            hasSubscriptions: true,
            isInverted: false,
            showMainItemsInSubNavigation: true,
            showSearchBarInMainNavigation: false,
        },
    },
};

export const useHeaderThemeConfig = () => useThemeConfig(THEME_CONFIG);
