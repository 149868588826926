import { AxiosRequestConfig } from 'axios';

import { ContextData } from '@common/defaults';
import { PartialUserContext } from '@common/defaults/UserContext';

import { PlatformID } from '../api/types.gen';
import { ApiBaseRequest, PartialContextData } from './ApiBaseRequest';
import { CrossPlatformApiBaseRequest } from './CrossPlatformApiBaseRequest';
import { SportmonksF1BaseRequest } from './SportmonksF1BaseRequest';

type ServiceName = 'CrossPlatformApiBaseRequest' | 'ApiBaseRequest' | 'SportmonksF1BaseRequest';

interface ServiceArgs {
    userContext?: PartialUserContext;
    contextData: PartialContextData;
    isClientSide?: boolean;
    config?: AxiosRequestConfig;
}
interface ApiServiceArgs<T, K = ApiBaseRequest> extends ServiceArgs {
    service: new (httpRequest: K) => T;
}
interface WithoutUserContext<T, K> extends Omit<ApiServiceArgs<T, K>, 'userContext'> {
    contextData: ContextData;
}
interface CrossPlatformApiBaseRequestArgs<T, K> extends WithoutUserContext<T, K> {
    platform: PlatformID;
}
interface SportsmonksF1BaseRequestArgs<T, K> extends WithoutUserContext<T, K> {}

const getServiceByName = <T>(service: ServiceName) => {
    const serviceMap = {
        SportmonksF1BaseRequest: ({
            contextData,
            isClientSide,
            config,
            service,
        }: SportsmonksF1BaseRequestArgs<T, SportmonksF1BaseRequest>): T => {
            const request = new SportmonksF1BaseRequest(contextData, Boolean(isClientSide), config);
            return new service(request);
        },
        CrossPlatformApiBaseRequest: ({
            contextData,
            platform,
            isClientSide,
            config,
            service,
        }: CrossPlatformApiBaseRequestArgs<T, CrossPlatformApiBaseRequest>) => {
            const request = new CrossPlatformApiBaseRequest(
                contextData,
                platform,
                Boolean(isClientSide),
                config,
            );
            return new service(request);
        },
        ApiBaseRequest: ({
            contextData,
            userContext,
            isClientSide,
            config,
            service,
        }: ApiServiceArgs<T, ApiBaseRequest>) => {
            const request = new ApiBaseRequest(contextData, Boolean(isClientSide), config, userContext);
            return new service(request);
        },
    };
    return serviceMap[service];
};

// Overload declarations for createApiService
export function createApiService<T>(): (args: ApiServiceArgs<T>) => T;
export function createApiService<T>(
    service: 'SportmonksF1BaseRequest',
): (args: SportsmonksF1BaseRequestArgs<T, SportmonksF1BaseRequest>) => T;
export function createApiService<T>(
    service: 'CrossPlatformApiBaseRequest',
): (args: CrossPlatformApiBaseRequestArgs<T, CrossPlatformApiBaseRequest>) => T;
export function createApiService<T>(service: 'ApiBaseRequest'): (args: ApiServiceArgs<T>) => T;
export function createApiService<T>(service?: ServiceName) {
    const createService = getServiceByName<T>(service || 'ApiBaseRequest');
    // any is mitigated by the explicit return
    // type of createService and the overload declarations
    return (args: any) => createService(args);
}

export function ApiService<T>(args: ApiServiceArgs<T>): T {
    return createApiService<T>('ApiBaseRequest')(args);
}

export function SportmonksF1Service<T>(args: SportsmonksF1BaseRequestArgs<T, SportmonksF1BaseRequest>): T {
    return createApiService<T>('SportmonksF1BaseRequest')(args);
}

export function CrossPlatformApiService<T>(
    args: CrossPlatformApiBaseRequestArgs<T, CrossPlatformApiBaseRequest>,
): T {
    return createApiService<T>('CrossPlatformApiBaseRequest')(args);
}
