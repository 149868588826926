import { NotificationCountContainer, NotificationService } from '@common/clients/api';
import { ApiService } from '@common/clients/request/ApiService';
import { ContextData, generateCatch } from '@common/defaults';

export const fetchUnreadCountNotifications = async (
    contextData: ContextData,
): Promise<NotificationCountContainer> =>
    ApiService({
        contextData,
        isClientSide: true,
        service: NotificationService,
    })
        .getUnreadNotificationsCountNotificationByPlatform({
            platform: contextData.platform.id,
        })
        .catch(generateCatch({ count: 0 }));
