import FsBackend from 'i18next-fs-backend';
import { type UserConfig, useTranslation as i18NextUseTranslation } from 'next-i18next';

import { BACKEND_LOAD_PATH, defaultLocale, LOCALE_PATH, locales } from './config';
import { isDev, shouldDebug, shouldUseI18Next } from './env';
import { missingKeyLogger } from './utils';
import { getFallbackNamespaces } from './utils/ns';

const ensureHyphens = (locale: string) => locale.replace('_', '-');

/**
 * @name getConfig
 * @description Get the i18n configuration object for i18next and NextJS (i18n)
 */
export const getConfig = (ns?: string[]): UserConfig => {
    ns ??= getFallbackNamespaces();

    return {
        ns,
        debug: shouldDebug,
        i18n: {
            defaultLocale: ensureHyphens(defaultLocale),
            locales: locales.map(ensureHyphens),
            localeDetection: false,
        },
        localePath: LOCALE_PATH,
        serializeConfig: false,
        saveMissing: isDev,
        missingKeyHandler: (_lng, ns, key) => {
            if (!isDev) return;

            console.warn(`Missing translation key: ${key}`);

            missingKeyLogger({ namespace: ns, i18nKey: key });

            return key;
        },
        backend: {
            loadPath: BACKEND_LOAD_PATH,
        },
        use: [FsBackend],
        reloadOnPrerender: isDev,
    };
};

// @todo: implement the app folder useTranslation hook
// @note: cast as any to avoid type errors for placeholder
const placeholderTranslation = () => ({ t: (key: string) => key });

/**
 * @name useTranslation hook
 * @description Temporary adapter hook for useTranslation function. This should be imported instead of the actual useTranslation hook from next-i18next.
 * @todo implement the app folder useTranslation hook
 * @example
 * import { useTranslation } from '@cms/i18n';
 *
 * const { t } = useTranslation('common');
 *
 * t('title')
 **/
export const useTranslation = shouldUseI18Next
    ? i18NextUseTranslation
    : // @todo: any cast to avoid type errors for placeholder
      (placeholderTranslation as any);
