import { FC } from 'react';
import Link from 'next/link';

import { useTranslation } from '@cms/i18n';
import { Icon } from '@common/atoms/Icon';
import { InternalLink, Route } from '@web/atoms/InternalLink';

import { useHeaderThemeConfig } from '../../Header.config';

import styles from './LoginButton.module.scss';

export interface Props {}

export const LoginButton: FC<Props> = () => {
    const themeConfig = useHeaderThemeConfig();
    const __meta = useTranslation('meta').t;

    return (
        <span className={styles.LoginButton}>
            <InternalLink className={styles['center-wrapper']} route={Route.Signin}>
                <Icon.userCircle />
                <span className={styles['label-login']}>{__meta('label.login')}</span>
            </InternalLink>
            {themeConfig.hasSubscriptions ? (
                <Link className={styles['subscribe-button']} href={__meta('url:subscribe')}>
                    {__meta('label.subscribe')}
                </Link>
            ) : null}
        </span>
    );
};
