import { User, UserService } from '@common/clients/api';
import { PartialContextData } from '@common/clients/request/ApiBaseRequest';
import { ApiService } from '@common/clients/request/ApiService';
import { defaultCatch } from '@common/defaults/defaultCatch';
import { UserContext } from '@common/defaults/UserContext';

export const fetchCurrentUserProfile = async (
    contextData: PartialContextData,
    userContext: UserContext | undefined,
    isClientSide: boolean = false,
): Promise<User | undefined> => {
    return ApiService({ contextData, userContext, isClientSide, service: UserService })
        .getProfileByIdUser()
        .catch(defaultCatch);
};
