import type { ParsedUrlQuery } from 'querystring';

import {
    AlternateLink,
    ExperimentGroupOrRobots,
    FullContext,
    NavItem,
    Platform,
    Tag,
} from '@common/clients/api';
import { AdsByPlacement } from '@common/types/AdsByPlacement';
import { ThemeID } from '@common/types/ThemeID';
import { ResolvedPromiseHolder } from '@common/utils/PromiseHolder';
import { ModulesBySection } from '@web/handlers/fetchActiveModules';
import { SidebarData } from '@web/organisms/Sidebar/types';
import { Route } from '@web/routing';

import { ApiProps } from './ApiProps';
import { CaptchaProps } from './CaptchaProps';
import { CrossPlatformByPlatformID } from './CrossPlatformByPlatformID';
import { SearchParams } from './SearchParams';

export interface CachedContextData {
    context?: FullContext;
    contexts: FullContext[];
    env: Record<string, string>;
    hostname: string;
    platform: Platform;
    config: {
        api: ApiProps;
        socket: ApiProps;
        sportmonksF1: ApiProps;
        crossPlatform: CrossPlatformByPlatformID;
        captchaConfig: CaptchaProps;
    };
}

export interface ContextData extends CachedContextData {
    ads?: AdsByPlacement | Promise<AdsByPlacement | undefined>;
    allowAds: boolean | 'preview';
    allowBetting: boolean | 'preview' | Promise<boolean>;
    allowTracking: boolean;
    alternateLinks?: AlternateLink[] | Promise<AlternateLink[]>;
    context: FullContext;
    experimentGroup?: ExperimentGroupOrRobots;
    isDarkWebCall?: boolean; // TODO: remove this once php code is gone
    isMobileApp?: boolean;
    isPlatformContext: boolean;
    modulesBySection?: ModulesBySection | Promise<ModulesBySection | undefined>;
    route?: Route;
    query?: ParsedUrlQuery;
    path?: string;
    params?: SearchParams;
    sidebarProps?: SidebarData | Promise<SidebarData | undefined>;
    navItems?: NavItem[] | Promise<NavItem[]>;
    themeID: ThemeID;
    trendingTags?: Tag[] | Promise<Tag[]>;
}

export type ResolvedContextData = ResolvedPromiseHolder<ContextData>;

export const isContextData = (x: ContextData | any): x is ContextData => !!x?.config?.api?.host;
