import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserContext } from 'supertokens-auth-react';

import { useTranslation } from '@cms/i18n';
import { ContextData } from '@common/defaults';
import { QueryUtil } from '@common/utils/QueryUtil';
import { fetchUnreadCountNotifications } from '@web/handlers/fetchNotifications';
import { MediaQuery, MediaQueryDirection, useResponsive } from '@web/hooks/useResponsive/useResponsive';
import { formatInternalRoute, Route } from '@web/routing';

import { useNavItems } from './useNavItems';

export enum MenuView {
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export enum PositionPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
}

interface MenuState {
    menuView: MenuView | '';
    currentSearch: string;
    menuOpen: boolean;
    expandedSearch: PositionPlacement | '';
    notificationsCount: number;
}

type Router = ReturnType<typeof useRouter>;
const getCurrentSearch = (router: Router) => {
    if (router.route === `/${Route.Search}`) {
        return QueryUtil.getQueryParam(router.query, 'q') || '';
    }
    return '';
};

export const useHeaderData = (contextData: ContextData) => {
    const __url = useTranslation('url').t;
    const router = useRouter();
    const userContext = useUserContext();
    const isLoggedIn = userContext?.state?.isLoggedIn;
    const { currentNavItem, mainItems, subItems, allItems } = useNavItems();

    const [menuState, setMenuState] = useState<MenuState>({
        menuView: '',
        currentSearch: getCurrentSearch(router),
        menuOpen: false,
        expandedSearch: '',
        notificationsCount: 0,
    });

    const [hasLoadedCount, setHasLoadedCount] = useState(false);

    useEffect(() => {
        const fetchAndUpdateNotifications = async () => {
            if (!hasLoadedCount && isLoggedIn) {
                const _notificationsCount: number = (await fetchUnreadCountNotifications(contextData)).count;

                setMenuState((prevState) => ({
                    ...prevState,
                    notificationsCount: _notificationsCount,
                }));
                setHasLoadedCount(true);
            }
        };
        fetchAndUpdateNotifications();
    }, [contextData, hasLoadedCount, isLoggedIn]);

    const isBreakpointLarge = useResponsive({
        direction: MediaQueryDirection.above,
        size: MediaQuery.l,
    });

    useEffect(() => {
        const setMenuView = (view: MenuView) => {
            setMenuState((prevState: MenuState) => ({
                ...prevState,
                menuView: view,
            }));
        };

        return isBreakpointLarge ? setMenuView(MenuView.DESKTOP) : setMenuView(MenuView.MOBILE);
    }, [isBreakpointLarge]);

    const toggleMenu = useCallback(() => {
        setMenuState((prevState: MenuState) => ({
            ...prevState,
            menuOpen: !prevState.menuOpen,
            expandedSearch: '',
        }));
    }, []);

    const submitSearch = useCallback(
        (searchText: string) => {
            const formattedRoute = formatInternalRoute(Route.Search, contextData.context.slug, __url);
            // router.push doesn't work due to glitch in Next.js: https://github.com/vercel/next.js/issues/22325
            window.location.href = `${formattedRoute}?q=${encodeURIComponent(searchText)}`;
        },
        [__url, contextData.context.slug],
    );

    return {
        menuState,
        isLoggedIn,
        items: menuState.menuOpen ? allItems : mainItems,
        currentNavItem,
        mainItems,
        subItems,
        toggleMenu,
        submitSearch,
    };
};
