import { Route } from './routing/types/Route';

export const defaultNamespaces = [
    'actions',
    'betting',
    'common',
    'components',
    'datetime',
    'error',
    'footer',
    'meta',
    'newslist',
    'secure',
    'sidebar',
    'stats',
    'url',
];

export const routeNamespaces: { [Key in Route]?: string[] } = {
    // Routes matching the Route enum
    [Route.Homepage]: ['videos'],
    [Route.Sandbox]: ['sandbox'],
    [Route.User]: ['user', 'author'],
    [Route.Search]: ['search'],
    [Route.Comments]: ['comments', 'post-comment'],

    // Routes matching '/livescores'
    [Route.Match]: ['livescores'],
    [Route.MatchByID]: ['livescores'],
    [Route.MatchOverviewDay]: ['livescores'],
    [Route.MatchOverview]: ['livescores'],

    // Routes matching '/competitions'
    [Route.CompetitionOverview]: ['livescores', 'competitions'],
    [Route.CompetitionTopscorers]: ['livescores', 'competitions'],
    [Route.CompetitionAssists]: ['livescores', 'competitions'],
    [Route.CompetitionCards]: ['livescores', 'competitions'],

    // Routes matching '/clubs'
    [Route.Club]: ['livescores'],
    [Route.ClubOverview]: ['livescores'],

    // Routes matching '/news'
    [Route.Article]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
    [Route.ArticlePreview]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
    [Route.TransferNews]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
    [Route.CategoryNews]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
    [Route.Podcast]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],
    [Route.BrightPodcast]: ['articles', 'comments', 'post-comment', 'livescores', 'videos'],

    // Routes matching '/videos'
    [Route.VideoArticle]: ['articles', 'comments', 'post-comment', 'videos'],
    [Route.VideoCategory]: ['articles', 'comments', 'post-comment', 'videos'],
    [Route.VideoOverview]: ['articles', 'comments', 'post-comment', 'videos'],

    // Routes matching '/profile'
    [Route.Profile]: ['profile'],
    [Route.Settings]: ['profile'],
    [Route.Notifications]: ['profile'],

    // Routes matching '/subscribe'
    [Route.Subscribe]: ['subscribe'],
    [Route.SubscribeSuccess]: ['subscribe'],

    // Routes matching '/secure'
    [Route.VerifyEmail]: ['secure'],
    [Route.Signin]: ['secure'],
    [Route.PasswordReset]: ['secure'],
    [Route.AttemptRefresh]: ['secure'],
};
