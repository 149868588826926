import JsCookies from 'js-cookie';

import { PartialUserContext } from '@common/defaults/UserContext';

import { SupertokensStatus } from './SupertokensStatus';

export const updateStatus = (
    userContext: PartialUserContext | undefined,
    isClientSide: boolean,
    fromStatusCode?: number,
) => {
    if (!userContext?.supertokens) {
        return SupertokensStatus.UNKNOWN;
    }

    if (isClientSide) {
        userContext.supertokens.sFrontToken = JsCookies.get('sFrontToken') || '';
    }

    if (!userContext.supertokens.sAccessToken) {
        userContext.supertokens.status = SupertokensStatus.NOT_LOGGED_IN;
    } else if (fromStatusCode === 401) {
        userContext.supertokens.status = SupertokensStatus.NEEDS_REFRESH;
    } else if (userContext.supertokens.status !== SupertokensStatus.NEEDS_REFRESH) {
        // Assume the user is logged in, api will tell us if he/she's not
        userContext.supertokens.status = SupertokensStatus.LOGGED_IN;
    }

    return userContext.supertokens.status;
};
