import { createContext } from 'react';

import { LocaleID, PlatformID, SubjectID } from '@common/clients/api';
import { ResolvedContextData } from '@common/defaults';
import { ThemeID } from '@common/types/ThemeID';

export const NextContext = createContext<Readonly<ResolvedContextData>>({
    allowAds: true,
    allowBetting: true,
    allowTracking: true,
    contexts: [],
    env: {},
    hostname: '',
    isPlatformContext: false,
    context: {
        id: 0,
        locale: LocaleID.EN,
        platform: PlatformID.VP,
        slug: '',
        subject: SubjectID.FOOTBALL,
        url: '',
        name: '',
        nameSimple: '',
        twitter: '',
        facebook: '',
        instagram: '',
        tiktok: '',
        youtube: '',
        address: '',
    },
    platform: {
        id: PlatformID.VP,
        name: '',
        subject: SubjectID.FOOTBALL,
        jwplayerKey: '',
    },
    config: {
        api: {
            host: '',
            timeout: 0,
        },
        socket: {
            host: '',
            timeout: 0,
        },
        sportmonksF1: {
            host: '',
            timeout: 0,
        },
        crossPlatform: {},
        captchaConfig: {
            siteKey: '',
        },
    },
    themeID: ThemeID.VP,
});
