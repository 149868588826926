import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { Interceptors } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';

import { AdService } from './services.gen';
import { AdsTxtService } from './services.gen';
import { AiService } from './services.gen';
import { AiPromptService } from './services.gen';
import { AuditLogService } from './services.gen';
import { BannerService } from './services.gen';
import { BinaryPollService } from './services.gen';
import { BlockedDomainService } from './services.gen';
import { BlockedIpService } from './services.gen';
import { BookmakerService } from './services.gen';
import { CommentService } from './services.gen';
import { CommentLikeService } from './services.gen';
import { CompetitionService } from './services.gen';
import { ConfigService } from './services.gen';
import { ContactFormControllerService } from './services.gen';
import { CuratedNewsService } from './services.gen';
import { CuratedNewsItemService } from './services.gen';
import { ExperimentService } from './services.gen';
import { FeaturedCarouselService } from './services.gen';
import { FileService } from './services.gen';
import { FilteredCommentService } from './services.gen';
import { FlaggedCommentService } from './services.gen';
import { ForbiddenWordService } from './services.gen';
import { GiftService } from './services.gen';
import { GrandPrixService } from './services.gen';
import { HeadlinesDossierService } from './services.gen';
import { ImportService } from './services.gen';
import { KambiService } from './services.gen';
import { LocaleService } from './services.gen';
import { LokaliseService } from './services.gen';
import { MatchService } from './services.gen';
import { MatchOddsService } from './services.gen';
import { MatchTickerService } from './services.gen';
import { MobileConfigService } from './services.gen';
import { ModuleScheduleService } from './services.gen';
import { MonitoringService } from './services.gen';
import { NavItemService } from './services.gen';
import { NewsService } from './services.gen';
import { NewsCategoryService } from './services.gen';
import { NewsMarketingBlockService } from './services.gen';
import { NotificationService } from './services.gen';
import { NotificationBarService } from './services.gen';
import { OddsDossierService } from './services.gen';
import { PageService } from './services.gen';
import { PlatformControllerService } from './services.gen';
import { PlayerService } from './services.gen';
import { PollService } from './services.gen';
import { PropositionService } from './services.gen';
import { RedirectService } from './services.gen';
import { SecureService } from './services.gen';
import { SitemapService } from './services.gen';
import { StatusService } from './services.gen';
import { SubscriptionService } from './services.gen';
import { SuperSecretService } from './services.gen';
import { TagService } from './services.gen';
import { TeamService } from './services.gen';
import { TestEventControllerService } from './services.gen';
import { TierService } from './services.gen';
import { TierGroupService } from './services.gen';
import { TotoService } from './services.gen';
import { TournamentService } from './services.gen';
import { UnibetService } from './services.gen';
import { UserService } from './services.gen';
import { UspService } from './services.gen';
import { VideofeedService } from './services.gen';
import { VideofeedConfigService } from './services.gen';
import { ViMigrationService } from './services.gen';
import { WebpService } from './services.gen';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

	public readonly ad: AdService;
	public readonly adsTxt: AdsTxtService;
	public readonly ai: AiService;
	public readonly aiPrompt: AiPromptService;
	public readonly auditLog: AuditLogService;
	public readonly banner: BannerService;
	public readonly binaryPoll: BinaryPollService;
	public readonly blockedDomain: BlockedDomainService;
	public readonly blockedIp: BlockedIpService;
	public readonly bookmaker: BookmakerService;
	public readonly comment: CommentService;
	public readonly commentLike: CommentLikeService;
	public readonly competition: CompetitionService;
	public readonly config: ConfigService;
	public readonly contactFormController: ContactFormControllerService;
	public readonly curatedNews: CuratedNewsService;
	public readonly curatedNewsItem: CuratedNewsItemService;
	public readonly experiment: ExperimentService;
	public readonly featuredCarousel: FeaturedCarouselService;
	public readonly file: FileService;
	public readonly filteredComment: FilteredCommentService;
	public readonly flaggedComment: FlaggedCommentService;
	public readonly forbiddenWord: ForbiddenWordService;
	public readonly gift: GiftService;
	public readonly grandPrix: GrandPrixService;
	public readonly headlinesDossier: HeadlinesDossierService;
	public readonly import: ImportService;
	public readonly kambi: KambiService;
	public readonly locale: LocaleService;
	public readonly lokalise: LokaliseService;
	public readonly match: MatchService;
	public readonly matchOdds: MatchOddsService;
	public readonly matchTicker: MatchTickerService;
	public readonly mobileConfig: MobileConfigService;
	public readonly moduleSchedule: ModuleScheduleService;
	public readonly monitoring: MonitoringService;
	public readonly navItem: NavItemService;
	public readonly news: NewsService;
	public readonly newsCategory: NewsCategoryService;
	public readonly newsMarketingBlock: NewsMarketingBlockService;
	public readonly notification: NotificationService;
	public readonly notificationBar: NotificationBarService;
	public readonly oddsDossier: OddsDossierService;
	public readonly page: PageService;
	public readonly platformController: PlatformControllerService;
	public readonly player: PlayerService;
	public readonly poll: PollService;
	public readonly proposition: PropositionService;
	public readonly redirect: RedirectService;
	public readonly secure: SecureService;
	public readonly sitemap: SitemapService;
	public readonly status: StatusService;
	public readonly subscription: SubscriptionService;
	public readonly superSecret: SuperSecretService;
	public readonly tag: TagService;
	public readonly team: TeamService;
	public readonly testEventController: TestEventControllerService;
	public readonly tier: TierService;
	public readonly tierGroup: TierGroupService;
	public readonly toto: TotoService;
	public readonly tournament: TournamentService;
	public readonly unibet: UnibetService;
	public readonly user: UserService;
	public readonly usp: UspService;
	public readonly videofeed: VideofeedService;
	public readonly videofeedConfig: VideofeedConfigService;
	public readonly viMigration: ViMigrationService;
	public readonly webp: WebpService;

	public readonly request: BaseHttpRequest;

	constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
		this.request = new HttpRequest({
			BASE: config?.BASE ?? '',
			VERSION: config?.VERSION ?? '1.0.0',
			WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
			CREDENTIALS: config?.CREDENTIALS ?? 'include',
			TOKEN: config?.TOKEN,
			USERNAME: config?.USERNAME,
			PASSWORD: config?.PASSWORD,
			HEADERS: config?.HEADERS,
			ENCODE_PATH: config?.ENCODE_PATH,
			interceptors: {
				request: config?.interceptors?.request ?? new Interceptors(),
				response: config?.interceptors?.response ?? new Interceptors(),
      },
		});

		this.ad = new AdService(this.request);
		this.adsTxt = new AdsTxtService(this.request);
		this.ai = new AiService(this.request);
		this.aiPrompt = new AiPromptService(this.request);
		this.auditLog = new AuditLogService(this.request);
		this.banner = new BannerService(this.request);
		this.binaryPoll = new BinaryPollService(this.request);
		this.blockedDomain = new BlockedDomainService(this.request);
		this.blockedIp = new BlockedIpService(this.request);
		this.bookmaker = new BookmakerService(this.request);
		this.comment = new CommentService(this.request);
		this.commentLike = new CommentLikeService(this.request);
		this.competition = new CompetitionService(this.request);
		this.config = new ConfigService(this.request);
		this.contactFormController = new ContactFormControllerService(this.request);
		this.curatedNews = new CuratedNewsService(this.request);
		this.curatedNewsItem = new CuratedNewsItemService(this.request);
		this.experiment = new ExperimentService(this.request);
		this.featuredCarousel = new FeaturedCarouselService(this.request);
		this.file = new FileService(this.request);
		this.filteredComment = new FilteredCommentService(this.request);
		this.flaggedComment = new FlaggedCommentService(this.request);
		this.forbiddenWord = new ForbiddenWordService(this.request);
		this.gift = new GiftService(this.request);
		this.grandPrix = new GrandPrixService(this.request);
		this.headlinesDossier = new HeadlinesDossierService(this.request);
		this.import = new ImportService(this.request);
		this.kambi = new KambiService(this.request);
		this.locale = new LocaleService(this.request);
		this.lokalise = new LokaliseService(this.request);
		this.match = new MatchService(this.request);
		this.matchOdds = new MatchOddsService(this.request);
		this.matchTicker = new MatchTickerService(this.request);
		this.mobileConfig = new MobileConfigService(this.request);
		this.moduleSchedule = new ModuleScheduleService(this.request);
		this.monitoring = new MonitoringService(this.request);
		this.navItem = new NavItemService(this.request);
		this.news = new NewsService(this.request);
		this.newsCategory = new NewsCategoryService(this.request);
		this.newsMarketingBlock = new NewsMarketingBlockService(this.request);
		this.notification = new NotificationService(this.request);
		this.notificationBar = new NotificationBarService(this.request);
		this.oddsDossier = new OddsDossierService(this.request);
		this.page = new PageService(this.request);
		this.platformController = new PlatformControllerService(this.request);
		this.player = new PlayerService(this.request);
		this.poll = new PollService(this.request);
		this.proposition = new PropositionService(this.request);
		this.redirect = new RedirectService(this.request);
		this.secure = new SecureService(this.request);
		this.sitemap = new SitemapService(this.request);
		this.status = new StatusService(this.request);
		this.subscription = new SubscriptionService(this.request);
		this.superSecret = new SuperSecretService(this.request);
		this.tag = new TagService(this.request);
		this.team = new TeamService(this.request);
		this.testEventController = new TestEventControllerService(this.request);
		this.tier = new TierService(this.request);
		this.tierGroup = new TierGroupService(this.request);
		this.toto = new TotoService(this.request);
		this.tournament = new TournamentService(this.request);
		this.unibet = new UnibetService(this.request);
		this.user = new UserService(this.request);
		this.usp = new UspService(this.request);
		this.videofeed = new VideofeedService(this.request);
		this.videofeedConfig = new VideofeedConfigService(this.request);
		this.viMigration = new ViMigrationService(this.request);
		this.webp = new WebpService(this.request);
	}
}
