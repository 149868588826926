import { useMemo } from 'react';
import { useRouter } from 'next/router';

import { NavItem } from '@common/clients/api';
import { useContextData } from '@common/useContextData';

import { useHeaderThemeConfig } from '../Header.config';

interface State {
    mainItems: NavItem[];
    currentNavItem?: NavItem;
    subItems: NavItem[];
    allItems: NavItem[];
}

export const useNavItems = (): State => {
    const { navItems = [] } = useContextData();
    const { asPath } = useRouter();
    const themeConfig = useHeaderThemeConfig();

    let mainItems = useMemo(() => navItems.filter((item) => item.isMain), [navItems]);
    let subItems = useMemo(() => navItems.filter((item) => !item.isMain), [navItems]);

    if (themeConfig.showMainItemsInSubNavigation) {
        subItems = mainItems;
        mainItems = [];
    }

    const allItems = useMemo(() => [...mainItems, ...subItems], [mainItems, subItems]);
    const currentNavItem = useMemo(() => allItems.find((item) => item.url === asPath), [allItems, asPath]);

    return {
        mainItems,
        currentNavItem,
        subItems,
        allItems,
    };
};
