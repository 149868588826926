import SuperTokens from 'supertokens-auth-react';
import EmailPassword from 'supertokens-auth-react/recipe/emailpassword';
import EmailVerification from 'supertokens-auth-react/recipe/emailverification';
import Multitenancy from 'supertokens-auth-react/recipe/multitenancy';
import Session from 'supertokens-auth-react/recipe/session';

import { ContextData } from '@common/defaults';
import { logger } from '@common/logger';
import { commonAppInfo } from '@common/supertokens/commonAppInfo';

let isInitialized = false;
export const initSupertokens = (contextData?: ContextData) => {
    const hostName = contextData?.context?.url;
    const apiDomain = contextData?.config?.api?.host;

    if (isInitialized || typeof window === 'undefined') {
        return;
    } else if (!contextData) {
        logger.warn(
            "initSupertokens: contextData is undefined. Can't tell if Supertokens should be initialized.",
        );
        return;
    } else if (!apiDomain || !hostName) {
        logger.error('initSupertokens: cannot init supertokens since hostName and/or apiDomain are not set.');
        return;
    }

    isInitialized = true;
    const baseHostName = `.${hostName.replace(/^www\./, '')}`;
    const protocol = process.env.NODE_ENV === 'development' ? 'http://' : 'https://';
    SuperTokens.init({
        enableDebugLogs: contextData.env.NEXT_PUBLIC_SUPERTOKENS_DEBUG === 'true',
        appInfo: {
            // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
            ...commonAppInfo,
            apiDomain,
            websiteDomain: `${protocol}${hostName}`,
        },
        clientType: contextData.context.platform,
        recipeList: [
            EmailPassword.init(),
            Session.init({
                // See https://supertokens.com/docs/emailpassword/common-customizations/sessions/multiple-api-endpoints#step-2-frontend-config
                sessionTokenBackendDomain: baseHostName,
                // See https://supertokens.com/docs/emailpassword/common-customizations/sessions/share-sessions-across-sub-domains
                sessionTokenFrontendDomain: baseHostName,
                // Number of times the Supertokens SDK will try to refresh the session and retry the 401-returning request before giving up. 1 indicates that it will retry once, which should be enough.
                maxRetryAttemptsForSessionRefresh: 1,
            }),
            EmailVerification.init({
                disableDefaultUI: true,
            }),
            Multitenancy.init({
                override: {
                    functions: (originalImplementation) => {
                        return {
                            ...originalImplementation,
                            // Tell Supertokens which tenant ID to assign new users to
                            getTenantId: () => contextData.context.platform,
                        };
                    },
                },
            }),
        ],
    });
};
